import React from "react";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CountUp from "react-countup";
import banner_image from "../../images/siteImages/companyProfile/product_statement.png";
import PaymentSlider from "./PaymentSlider";

const ProductStatement = () => {
  const language = useSelector((state) => state.language);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (

    <Layout >
      <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg p-top-10 p-bottom-80 faqs">
        <Container className="">
          <Row>
            <Col lg={12}>
              <h2 className="pt-5 mt-5 text-bold-md text-black">
                Product Statement
              </h2>
              <h4 className="pt-5 my-3 text-bold-md text-black">Product Statement and Execution Risks</h4>
              <p className="text-gray my-3">Trading in forex involves substantial risks, including possible and complete loss of funds and is not suitable for all individuals. Clients should make an independent judgment as to whether trading is appropriate for them in the light of their financial condition, investment experience, risk tolerance and other factors. Rox Capitals Services Limited does not accept account registrations from residents of the United States of America.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Execution Model-STP</h4>
              <p className="text-gray my-3">Rox Capitals provides forex execution STP (Straight Through Processing). As the forex market trades OTC (over-the-counter), an STP environment allows traders to place their orders directly into the marketplace and taking advantage of multiple Tier 1 liquidity providers. Rox Capitals uses the latest FIX (Financial Information exchange) protocol specifications to provide direct and transparent execution with real trading conditions to all market participants. With our STP model, customers receive direct access to the best bid and offer available from our Tier 1 banking partners. With Fidelis your orders are executed under 100 sub milli seconds.</p>
              <b className="text-gray my-3">Advantages of our STP model</b>
              <ul className="privacy-list">
                <li className="text-gray my-3">Direct Market Access with true bid and offer available for quick, fair execution.</li>
                <li className="text-gray my-3">Market execution capabilities with instant fills on next available bid or offer</li>
                <li className="text-gray my-3">Unique order management interface with institutional level market access</li>
                <li className="text-gray my-3">Scalping is strictly prohibited. Any trade closed within 180 seconds will be treated as scalping and any profit earned out of such trading will not be paid.</li>
                <li className="text-gray my-3">Unique back office functionality with hedging allowed with no FIFO restrictions</li>
                <li className="text-gray my-3">Direct API available for algorithmic and systematic trading developers</li>
                <li className="text-gray my-3">Level 1 panel with deep liquidity for increased trading transparency</li>
              </ul>

              <h4 className="pt-5 my-3 text-bold-md text-black">Demo Accounts</h4>
              <p className="text-gray my-3">Although demo accounts manage to simulate real accounts, there are key factors that differentiate them to a real trading environment. The funds in the account are virtual, eliminating the risk factor of trading, which sometimes is the force of FX trading, and there's no real dependence on real-time market liquidity. The operational capabilities when executing orders in a demo environment may result in atypically, expedited transactions; lack of rejected orders; and/or the absence of slippage. Additionally, updates to demo accounts may not coincide with those of real accounts.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Slippage</h4>
              <p className="text-gray my-3">Rox Capitals aims to provide clients with the best execution available and to have all orders filled at the requested price. However, there are times when, due to an increase in volatility or volume, orders may be subject to slippage. Slippage most commonly occurs during fundamental news events or periods of limited liquidity. During periods such as these, your order type, quantity demanded, and specific order instructions can have an impact on the execution of your order.</p>
              <b className="text-gray my-3">Examples of specific order instructions include:</b>
              <ul className="privacy-list">
                <li className="text-gray my-3">Good Till Cancelled (“GTC”) Orders: Your entire order will be filled at the next available price(s) at the time it is received.</li>
                <li className="text-gray my-3">Immediate or Cancel (“IOC”) Orders: All or part of your order will be filled at the next available price with the remaining amount cancelled should liquidity not exist to fill your order immediately.</li>
                <li className="text-gray my-3">Fill or Kill (“FOK”) Orders: The order must be filled in its entirety or not at all.</li>
                <li className="text-gray my-3">The volatility in the market may create conditions where orders are difficult to execute. For instance, the price you receive in the execution of your order might be many pips away from the selected or quoted price due to market movement. In this scenario, you may be looking to execute at a certain price but in a split second, for example, the market may have moved significantly away from that price. Your order would then be filled at the next available price for that specific order. Similarly, given Rox Capitals' model for forex trade execution, sufficient liquidity must exist to execute all trades at any given price.</li>
                <li className="text-gray my-3">Rox Capitals Market Opinions</li>
                <li className="text-gray my-3">Any opinions, news, research, analyses, prices, or other information contained on this website is provided as general market commentary, and does not constitute investment advice or financial advice. If it is determined or held that it is financial advice then it is general only. By using this website, you acknowledge and agree that the information set out on this website has been provided without taking your financial situation, objectives or goals into account. Rox Capitals will not accept liability for any loss or damage (whether arising under contract or tort (including negligence) or otherwise), including without limitation any loss of profit, which may arise directly or indirectly from use of or reliance on such information.</li>
              </ul>

              <h3 className="pt-5 my-3 text-bold-md text-black">Execution Risks</h3>
              <h4 className="pt-5 my-3 text-bold-md text-black">1. Internet Trading Risks</h4>
              <p className="text-gray my-3">There are risks associated with utilizing an internet-based deal-execution trading system including, but not limited to, the failure of hardware, software, and internet connection. Since Rox Capitals does not control signal power, its reception or routing via the internet, configuration of your equipment or reliability of its connection, we cannot be responsible for communication failures, distortions or delays when trading via the internet. To try and maximize your enjoyment and use of Rox Capitals' service, Rox Capitals employs backup systems and contingency plans to minimize the possibility of system disruption or failure.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">2. Liquidity</h4>
              <p className="text-gray my-3">During the first few hours after opening, the market tends to be thinner than usual until the Tokyo and London market sessions begin. These thinner markets may result in wider spreads, as there are fewer buyers and sellers. This is largely due to the fact that for the first few hours after the opening, it is still the weekend in most of the world. There can be various other reasons in addition to the one stated for thinner liquidity. In illiquid markets, traders may find it difficult to enter or exit positions at their requested price, experience delays in execution, and receive a price at execution that is a significant number of pips away from your requested rate.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">3. Delays In Execution</h4>
              <p className="text-gray my-3">A delay in execution may occur for various reasons, such as technical issues with the trader's internet connection to Rox Capitals' STP, a delay in order confirmation from a liquidity provider, or a lack of available liquidity for the currency pair that the trader is attempting to trade. Due to inherent volatility in the markets, it is imperative that traders have a working and reliable internet connection. There are circumstances when the trader's personal internet connection may not be maintaining a constant connection with the Rox Capitals servers due to a lack of signal strength from a wireless or dialup connection.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">4. Volume</h4>
              <p className="text-gray my-3">When the underlying market is very active, the volume of trade/orders entering the online trading platform increases tremendously, which affects the speed of execution. In these circumstances, it may also take longer for a trader to receive an order confirmation.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">5. Fast Markets</h4>
              <p className="text-gray my-3">A fast market is a high-volume trading session marked by extreme price fluctuations and order imbalances resulting from numerous investors entering buy or sell orders for the same currency simultaneously. Because of these imbalances, wide price variances in short periods of time are common. On any given day, fast markets can affect a particular currency, groups of currencies or the market as a whole. Fast markets can be caused by material news announcements, market developments and even trading halts taking place in less volatile currencies or in other securities. The ability to execute orders in fast market conditions may be severely limited, and order execution may be delayed significantly. Furthermore, market orders entered in fast market conditions may be executed at prices that are significantly different from the prices quoted at the time the orders were entered.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">6. Gapping</h4>
              <p className="text-gray my-3">Sunday's opening prices may or may not be the same as Friday's closing prices. At times, the prices on the Sunday openings are near where the prices were on the Friday's closing. At other times, there may be a significant difference between Friday's close prices and Sunday's opening prices. The market may gap if there is a significant news announcement or an economic event changing how the market views the value of a currency. Traders holding Positions or orders over the weekend should be fully comfortable with the potential of the market to gap.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">7. Weekend Risk</h4>
              <p className="text-gray my-3">Traders who fear that the markets may be extremely volatile over the weekend, that gapping may occur, or that the potential for weekend risk is not appropriate for their trading style, may simply close out orders and positions ahead of the weekend. It is imperative that traders who hold open positions over the weekend understand that the potential exists for major economic events and news announcements to affect the value of underlying positions. Given the volatility expressed in the markets it is not uncommon for prices to be a number of pips away on market open from market close. We encourage all traders to take this into consideration before making a trading decision.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">8. Rollover/Swap Rates</h4>
              <p className="text-gray my-3">Rollover is the simultaneous closing and opening of a position at a particular point during the day in order to avoid the settlement and delivery of the purchased currency. This term also refers to the interest either charged or applied to a trader's account for positions held “overnight,” meaning after 5 p.m. ET on trading Platforms. The time at which positions are closed and reopened, and the rollover fee is debited or credited, is commonly referred to as Trade Rollover (“TRO”). It is important to note that rollover charges will be higher than rollover accruals. When all positions are hedged in an account, although the overall net position may be flat, the account can still sustain losses due to the spread that occurs at the time rollover occurs. Spreads during rollover may be wider when compared to other time periods because of liquidity provider's momentarily coming offline to settle the day's transactions. For more on rollover/swap rates and how it works click here Roll-Over and Swap Rates.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">9. Margin Call Policy</h4>
              <p className="text-gray my-3">Please note that Rox Capitals does not provide a margin call warning to clients prior to liquidating open positions. Margin calls are triggered when your usable margin reaches zero. This occurs when your floating losses reduce your account equity to a level that is less than or equal to your margin requirement. Therefore, the result of any margin call is subsequent liquidation unless otherwise specified. Margin call is generated by your trading platform when your account value is equal to or less than a certain percentage of the Minimum Margin Requirement. We advise all clients and traders to strictly adhere to margin requirements when trading.</p>
              <p className="text-gray my-3">Minimum Margin Requirements on Open Positions must be maintained by the customer at all times. Any or all open positions are subject to liquidation by Rox Capitals should the Minimum Margin Requirement fail to be maintained.</p>
              <p className="text-gray my-3">Margin requirements may change at any time. Rox Capitals will do its best to inform the client about any projected changes by email and via the trading platform's message system at least a week before changes go into effect.</p>
              <p className="text-gray my-3">The placing of Stop Loss Orders, used to minimize losses, is the client's responsibility.</p>


              <h4 className="pt-5 my-3 text-bold-md text-black">10. Reset Orders</h4>
              <p className="text-gray my-3">Market volatility creates conditions that make it difficult to execute orders at the given price due to an extremely high volume of orders. By the time orders are able to be executed, the bid/ask price at which a counterparty is willing to take a position may be several pips away.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">11. Limit/Stop Orders</h4>
              <p className="text-gray my-3">limit/stop orders such as stop loss/buy limit/buy stop/sell limit are executed as market orders. It's not All guaranteed that your limit/stop orders will execute at the price set by you. Though Fidelis ensures that all orders are executed at your quoted price but due to increased volatility in the market, it is possible that you may experience a slippage of few pips under milli-sub seconds. In such volatile conditions Fidelis still executes your order in less than 200 milli sub seconds.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">12. Hanging Orders</h4>
              <p className="text-gray my-3">All limit/stop orders such as stop loss/buy limit/buy stop/sell limit are executed as market orders. It's not guaranteed that your limit/stop orders will execute at the price set by you. Though Fidelis ensures that all orders are executed at your quoted price but due to increased volatility in the market, it is possible that you may experience a slippage of few pips under milli-sub seconds. In such volatile conditions Fidelis still executes your order in less than 200 milli sub seconds.</p>
              <p className="text-gray my-3">During periods of high volume, hanging orders may occur. This is a condition where an order is in the process of executing but execution has not yet been confirmed. The order will be highlighted in red, and the “status” column will indicate “executed” or “processing,” in the “orders” window. In these instances, the order is in the process of being executed, but is pending until Rox Capitals receives confirmation from the liquidity provider that the quoted prices is still available. During periods of heavy trading volume, it is possible that a queue of orders will form. That increase in incoming orders may sometimes create conditions where there is a delay from the liquidity providers in confirming certain orders.</p>
              <p className="text-gray my-3">Depending on the type of order placed, outcomes may vary. In the case of a Market Range order that cannot be filled within the specified range, or if the delay has passed, the order will not be executed. In the case of an At Market order, every attempt will be made to fill the order at the next available price in the market. In both situations, the “status” column in the “orders” window will typically indicate “executed” or “processing.” The trade will simply take a few moments to move to the “open positions” window. Depending upon the order type, the position may in fact have been executed, and the delay is simply due to heavy internet traffic.</p>


              <h4 className="pt-5 my-3 text-bold-md text-black">13. Greyed Out Pricing</h4>
              <p className="text-gray my-3">Greyed out pricing is a condition that occurs when forex liquidity providers that supply pricing to Rox Capitals are not actively making a market for particular currency pairs and liquidity therefore decreases. Rox Capitals does not intentionally “grey out” prices; however, at times, a severe increase in the difference of the spread may occur due to a loss of connectivity with a liquidity provider or due to an announcement that has a dramatic effect on the market that limits liquidity. Such greying out of prices or increased spreads may result in margin calls on a client's account.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">14. Hedging</h4>
              <p className="text-gray my-3">The ability to hedge allows a trader to hold both buy and sell positions in the same currency pair simultaneously. Traders have the ability to enter the market without choosing a particular direction for a currency pair. Although hedging may mitigate or limit future losses it does not prevent the account from being subjected to further losses altogether. In the forex market a trader is able to fully hedge by quantity but not by price. This is because of the difference between the buy and sell prices, or the spread. At Rox Capitals, while a trader is fully hedged the margin requirement is zero, and when one leg of the hedged position is closed the remaining open exposure will be subject to the normal margin requirement for the specified pair. This can be monitored at all times in the simple dealing rates window. While the ability to hedge is an appealing feature, traders should be aware of the following factors that may affect hedged positions.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">15. Diminishing Margin</h4>
              <p className="text-gray my-3">A margin call may occur even when an account is fully hedged, since spreads may widen, causing the remaining margin in the account to diminish. Should the remaining margin be insufficient to maintain any open positions, the account may sustain a margin call, closing out any open positions in the account. Although maintaining a long and short p position may give the trader the impression that its exposure to the market's movement is limited, if insufficient available margin exists and spreads widen for any period of time, it may result in a margin call on all positions.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">16. Exchange Rate Fluctuations (Pip Costs)</h4>
              <p className="text-gray my-3">Exchange rate fluctuations, or pip costs, are defined as the value given to a pip movement for a particular currency pair. This cost is the currency amount that will be gained or lost with each pip movement of the currency pair's rate and will be denominated in the currency denomination of the account in which the pair is being traded.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">17. Governing Law And Jurisdiction</h4>
              <p className="text-gray my-3">This website shall be governed by the statutes and laws of Saint Vincent and the Grenadines Unless otherwise agreed, these terms and conditions and their enforcement are governed by the laws of Saint Vincent and the Grenadines, without regard to principles of conflicts of law, and shall inure to the benefit of Rox Capitals's successors and assigns, whether by merger, consolidation, or otherwise. This is the case regardless of whether you reside or transact business with Rox Capitals in of Saint Vincent and the Grenadines or elsewhere. Unless a dispute would be governed by an applicable arbitration clause, you irrevocably agree to submit to the jurisdiction of courts located in of Saint Vincent and the Grenadines, and hereby waive any objection to the convenience or propriety of venue therein or any similar grounds.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">18. Leverage</h4>
              <p className="text-gray my-3">Fidelis provide leverage up-to 500:1* on its forex trading accounts.<br></br>
                * Leverage is a double-edged sword and can dramatically amplify your profits. It can also just as dramatically amplify your losses. Trading foreign exchange with any level of leverage may not be suitable for all investors</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Notice To Online Traders</h4>
              <p className="text-gray my-3">At Rox Capitals, we want to encourage our clients to trade with caution, and not to rush with any decision-making process. We want to warn them that trading involves a substantial risk and both profits and losses can be made from trading. More importantly, we encourage our traders to arm themselves with knowledge and professional advice before undertaking such activity</p>
              <p className="text-gray my-3">Trading or investing in the forex market contains substantial risk and may not be suitable for all traders.</p>
              <b className="text-gray my-3">Three rules for trading:</b>
              <ul className="privacy-list">
                <li className="text-gray my-3">know what you are buying.</li>
                <li className="text-gray my-3">know the ground rules under which you decide to buy or sell a currency.</li>
                <li className="text-gray my-3">know the level of risk you are undertaking.</li>
              </ul>
              <p className="text-gray my-3">Our clients and prospective clients should remember that it is just as easy to lose money through the click of a mouse as it is to make it. Most people who jump into trading without a serious plan, lose money. For most individuals, the forex market should be a place to invest, not actively trade. The technology available at Rox Capitals is in many respects similar to that which is used by professional traders. Retail investors should exercise caution before attempting to imitate the style of trading and assuming the risks undertaken by market professionals.</p>
              <b className="text-gray my-3">Trading Tips: </b>
              <ul className="privacy-list">
                <li className="text-gray my-3">Create a systematic approach to trading</li>
                <li className="text-gray my-3">Trade with funds you can afford to lose</li>
                <li className="text-gray my-3">Use the most efficient forms of execution</li>
                <li className="text-gray my-3">Keep in mind that the lower the leverage, the less the risk</li>
                <li className="text-gray my-3">Be psychologically prepared- reduce your fear and keep calm</li>
              </ul>
              <p className="text-gray my-3">Regardless of the platform used, every internet trader or investor should be aware of the effects of systems failure, the enormous price volatility of many stocks, the possible negative results of using margin accounts (Margin Call Policy) and the risks associated with active trading.</p>
              <p className="text-gray my-3">System failure can affect anyone conducting business over the internet; among other things, trades might not be executed at all or trade executions could be at prices significantly different from the price quoted at the time the order was entered. There is a variety of reasons why a user could experience delays or failures in order placements, order cancellations, trade executions or trade reports. Among them are: increase in volume and demand beyond system capacity; errors or defects in the software or the data services; internet service providers having technical problems; communications lines problems or failures; satellite dish problems or failures; extended power outages; and sabotage created by hackers. The point we are making is that no system is perfect. Delays and failures are risks that you must assume. In using our systems, you accept that solely you are taking those risks, and that we cannot be held responsible by you if they occur, regardless of the reason. To put it plainly, you should not always expect high-speed order placements, cancellations, executions and reporting. We recommend that you have a back-up plan to place, cancel, execute and confirm orders if you encounter problems using Internet software and systems.</p>
              <p className="text-gray my-3">You should also be aware of the risks associated with trading in a rapidly-moving and sometimes volatile market and make sure that you think about how to limit your risk. For example, volatility can significantly affect your execution price if you are placing market orders. In other words, just because you see a price on your computer screen does not mean that you will get that price. To avoid buying or selling a currency at a price higher or lower than is acceptable to you, you might consider using limit orders rather than market orders. A limit order is an order to buy or sell a currency at no higher or lower than a specified price. However, using a limit order often results in the trade executions failing to occur. As you can see, trading, and particularly active online trading, is complex. You must understand what you are doing and the risks you are taking before you decide to trade.</p>
              <p className="text-gray my-3">You should also fully understand the risks associated with using a margin account. When you buy currencies on margin, you are borrowing money and pledging the currency in your account as collateral. In volatile markets, investors who have put up an initial margin payment (i.e., borrowed funds) for a currency may find themselves being required to provide additional cash (maintenance margin) if the price of the currency subsequently falls (a margin call). A brokerage firm has the right without notice to sell currencies (i.e., the collateral) chosen by the brokerage firm from the investor's account and charge any loss to the customer, possibly causing a loss greater than the initial investment. The brokerage firm can increase margin requirements at any time and is not required to grant an extension on any margin call. It is important for our clients to understand these concepts before they decide to trade on margin.</p>
              <p className="text-gray my-3">Along with our technology comes your responsibility to have a plan before you start trading.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Online Transfer Authorization Agreement</h4>
              <p className="text-gray my-3">By enrolling in Rox Capitals Online Transfer Account Authorization, you establish a standing authorization to request online electronic funds transfers between your Rox Capitals accounts and your accounts, as indicated below.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Wire Transfers</h4>
              <p className="text-gray my-3">By sending Rox Capitals a wire transfer request (whether by telephone, electronically or in writing), you authorize Rox Capitals to act on your behalf, to initiate the wire transfer. On receipt of a wire transfer request by Rox Capitals, Rox Capitals will transmit payment instructions to the applicable bank. If a wire transfer request is received after the relevant cut off time, the client's request may be treated as if it were received on the next business day. Rox Capitals also may reject wire transfer requests. You will be notified of any such rejection by telephone, electronically or in writing. Rox Capitals is not liable for any interest for the period before you receive the notice of rejection. It is your responsibility to ensure that your instructions are accurate before requesting Rox Capitals to initiate a wire transfer.</p>
              <p className="text-gray my-3">A wire transfer request cannot be amended or cancelled after Rox Capitals receives it. Rox Capitals may in its sole discretion attempt to abide by a subsequent request for a change, but it is not obligated to do so. You agree to indemnify and hold Rox Capitals and its affiliates blameless from any losses arising out of or relating to an attempt to amend or cancel a wire transfer request. In addition, if you request a stop-payment on any cheque issued in response to a cheque disbursement request, you understand that you may not have access to the funds for at least 60 (sixty) days. If your wire transfer request involves a currency other than your base account currency, your funds will be exchanged for such currency at the current rate of exchange according to Fidelis' standard business procedures. You are aware that currency exchange rates fluctuate over time and you accept the risks of such fluctuation between the time you send a wire transfer request and the time the wire transfer is final. If you arrange for a wire transfer to be directed to your Account, you are responsible for ensuring that such wire is initiated properly, addressed properly to Rox Capitals' bank account and bears appropriate wire instructions in exactly the form required by Rox Capitals for you and your account's identification. You understand that any erroneous, mismatched or incomplete identifying information on an incoming wire transfer may result in such wire being rejected, lost, posted to an incorrect account or returned to the originating bank without notice to you and you agree to indemnify and hold Rox Capitals and its affiliates harmless from any Losses arising out of or relating to any erroneous, mismatched or incomplete identifying information on an incoming wire. You will also have other options of withdrawing funds such as credit/debit card available on Rox Capitals website under your client terminal. </p>
              <p className="text-gray my-3">In order to open a trading account with Rox Capitals, applicants must provide a valid form of government-issued identification (Driver's License, Country/State ID, or Passport), a proof of residence. In addition, traders must submit a completed account application.</p>
              <p className="text-gray my-3">Account Opening Document Requirements</p>
              <p className="text-gray my-3">In order to open a Live trading account with Fidelis, you must submit documents to support the account. The documents required are a proof of identity and a proof of residence.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Proof of Identity</h4>
              <b className="text-gray my-3">The following are accepted as proof of identity</b>
              <ul className="privacy-list">
                <li className="text-gray my-3">A valid passport with recent photograph</li>
                <li className="text-gray my-3">A valid national ID card</li>
                <li className="text-gray my-3">A valid driver's license</li>
              </ul>

              <h4 className="pt-5 my-3 text-bold-md text-black">Proof of Residence</h4>
              <ul className="privacy-list">
                <li className="text-gray my-3">A utility bill not older than 3 months</li>
                <li className="text-gray my-3">A bank statement not older than 3 months</li>
              </ul>

              <h4 className="pt-5 my-3 text-bold-md text-black">Bank Account Requirements</h4>
              <p className="text-gray my-3">All Fidelis trading account holders must have a valid bank account, and/or credit/debit card in their name, which should be the same name as the trading account name. The bank statement should show also their name and the same registered address as provided upon the registration process</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Funds Withdrawal Procedures</h4>
              <p className="text-gray my-3">The withdrawal process at Rox Capitals is a strict and secure process which requires certain guidelines in order for the funds to be sent back to their original source and beneficiary.</p>
              <p className="text-gray my-3">Rox Capitals clients must complete a withdrawal request and provide all information required</p>
              <p className="text-gray my-3">All withdrawal forms are submitted to Rox Capitals accounts department for processing. Once approved, the withdrawal will take UP to 48 hours, except for bank wires which may take up to 5 working days.</p>
              <p className="text-gray my-3">Funds withdrawn will be returned to the original source of deposit, be it bank, credit or debit card, or e-wallet payment method. In the case that the withdrawal amount is in excess of the amount originally deposited, then the excess amount will be returned to the client by bank transfer.</p>
              <p className="text-gray my-3">In the event that a withdrawal is flagged for suspicious activity, the withdrawal is placed on hold, pending further investigation by the Compliance department.</p>

              <h4 className="pt-5 my-3 text-bold-md text-black">Account Opening Procedures</h4>
              <p className="text-gray my-3">Prior to opening an account, Rox Capitals shall document the identity, nature of business, income, source of assets if applicable, and investment objectives of each prospective customer. While we welcome clients from all over the globe, FATF restrictions along with our company policies prohibit Rox Capitals from opening accounts originated from certain countries which will be notified from time to time.</p>

            </Col>
          </Row>
        </Container>
      </section>

      <PaymentSlider/>
    </Layout>
  );
};

export default ProductStatement;